import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const DoveSiamo = () => (
  <Layout>
    <SEO
      title="Dove Siamo"
      keywords={[
        "pizzeriatheitalianjob",
        "castellanza",
        "pizzeria castellanza",
      ]}
    />
    <Content />
  </Layout>
)

export default DoveSiamo

const Content = () => (
  <div>
    <h1>Dove Siamo</h1>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <p>
          Pizzeria The Italian Job si trova a Castellanza, in Via Lombardia 18
        </p>
        <p>
          Facilmente raggiungibile dalla strada provinciale Saronnese offriamo
          anche consegna a domicilio nei comuni limitrofi di Legnano, Busto,
          Marnate e Olgiate Olona
        </p>
      </div>
      <div>
        <iframe
          title="Mappa"
          style={{ width: "100%", height: "450px", border: "0" }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5581.566318305959!2d8.9042112!3d45.6150014!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47868c3512516b05%3A0xf1ab9793d145247!2sViale+Lombardia%2C+18%2C+21053+Castellanza+VA!5e0!3m2!1sen!2sit!4v1557092840814!5m2!1sen!2sit"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </div>
  </div>
)
